import { ApiTypes } from '@wix/social-groups-api';
import { PrivacyUIMap } from './PrivacyMap';
import React from 'react';
import { Visible } from '../../../../common/components/Icons/Visible';
import { Secret } from '../../../../common/components/Icons';

export type VisibilityType =
  | ApiTypes.v1.GroupPrivacyLevel.PUBLIC
  | ApiTypes.v1.GroupPrivacyLevel.PRIVATE;

export const VisibilityMap: PrivacyUIMap = {
  [ApiTypes.v1.GroupPrivacyLevel.PRIVATE]: {
    title: 'groups-web.visibility.visible',
    label: 'groups-web.visibility.visible.label',
    icon: <Visible />,
  },
  [ApiTypes.v1.GroupPrivacyLevel.SECRET]: {
    title: 'groups-web.visibility.hidden',
    label: 'groups-web.visibility.hidden.label',
    icon: <Secret />,
  },
};
