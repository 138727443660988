import { Member as M } from '@wix/ambassador-members-v1-member/types';

export interface IMember {
  getImageUrl(): string;

  getName(anon?: string): string;

  getId(): string;
}

export class Member implements IMember {
  constructor(private readonly member: M) {}

  getId(): string {
    return this.member.id || '';
  }

  getImageUrl(): string {
    return this.member.profile?.photo?.url || '';
  }

  getName(anon?: string): string {
    return this.member.profile?.nickname || anon || '';
  }
}
