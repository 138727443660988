import React from 'react';
import { GroupCardProps } from '../types';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button } from '../../Button/Button';
import { isJoined, ApiTypes } from '@wix/social-groups-api';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { isUndefinedOrTrue } from '../../../utils/utils';
import { BIUserEntry } from '../../../bi-logger/types';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { widgetSettingsParams } from '../../../settings/groups-list/widgetSettings';
import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';
import { useUser } from '../../../context/user/useUser';
import { groupAdapter } from '@wix/social-groups-api/dist/src/model/Group/v2/GroupAdapter';
import { CTA_BUTTON } from 'common/components/GroupCard/dataHooks';

export const CTA_BUTTON_PREFERENCES: {
  [key in ApiTypes.v1.RelationshipWithGroup]: {
    buttonLabelKey: string;
    priority: PRIORITY;
  };
} = {
  [ApiTypes.v1.RelationshipWithGroup.NONE]: {
    buttonLabelKey: 'groups-web.btn.join-group',
    priority: PRIORITY.primary,
  },
  [ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL]: {
    buttonLabelKey: 'groups.web.btn.join-requested',
    priority: PRIORITY.secondary,
  },
  [ApiTypes.v1.RelationshipWithGroup.JOINED]: {
    buttonLabelKey: 'groups-web.btn.view-group',
    priority: PRIORITY.secondary,
  },
  [ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP]: {
    buttonLabelKey: 'groups-web.btn.join-group',
    priority: PRIORITY.primary,
  },
};

type Props = Omit<GroupCardProps, 'imageRatio' | 'mobile'> & {
  groupUrl?: string;
  className?: string;
};

export const GroupCardCTA: React.FC<Props> = ({
  group,
  groupUrl,
  className,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const bi = useBi();
  const { userActions } = useUser();
  // TODO: widgetSettingsParams?
  if (!isUndefinedOrTrue(settings.get(widgetSettingsParams.showButton))) {
    return null;
  }
  const ctaPreferences = CTA_BUTTON_PREFERENCES[group.relationship!];

  const onClickHandler = (event: any) => {
    event.stopPropagation();
    if (isJoined(group as any)) {
      bi.report(
        groupsSelectGroupFromList({
          origin: 'view_btn',
          userEntry: BIUserEntry.SITE,
          groupId: group.groupId!,
        }),
      );
      if (groupUrl) {
        window.location.href = groupUrl;
      }
    }
    userActions.changeGroupMembership(groupAdapter(group) as any); // TODO @wix/ambassador-social-groups-v2-group
  };
  return (
    <div className={className}>
      <Button
        data-hook={CTA_BUTTON}
        priority={ctaPreferences.priority}
        onClick={onClickHandler}
        fullWidth
      >
        {t(ctaPreferences.buttonLabelKey)}
      </Button>
    </div>
  );
};

GroupCardCTA.displayName = 'GroupCardCTA';
