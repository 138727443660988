import React from 'react';
import { AppToastsProps } from '@wix/social-groups-api/dist/src/model/AppToasts/types';

import { classes, st } from './AppToasts.st.css';
import { AppToast } from './AppToast';

interface ToastsProps extends AppToastsProps {
  // TODO: [YO] fix this
  t: any;
  className?: string;
}

export const AppToasts: React.FC<ToastsProps> = (props) => {
  const { t, acknowledge, entries } = props;
  if (!entries || !entries.length) {
    return null;
  }
  return (
    <div className={st(classes.root, {}, props.className)}>
      <div className={classes.toasts}>
        {entries.map((toast) =>
          toast ? (
            <AppToast t={t} toast={toast} acknowledge={acknowledge} />
          ) : null,
        )}
      </div>
    </div>
  );
};
AppToasts.displayName = 'AppToasts';
