import React from 'react';
import { Dropdown, DropdownProps } from 'wix-ui-tpa/Dropdown';
import { Text } from 'wix-ui-tpa/Text';

import { classes, st } from './LabeledDropdown.st.css';

export function LabeledDropdown(props: DropdownProps) {
  const { label, className, ...rest } = props;
  return (
    <div className={st(classes.root, {})}>
      <Text className={classes.label}>{label}</Text>
      <Dropdown
        className={st(classes.dropdown, {}, className)}
        placement="bottom"
        {...rest}
      />
    </div>
  );
}

LabeledDropdown.displayName = 'LabeledDropdown';
