import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes, isAdmin, isJoined } from '@wix/social-groups-api';
import { IGroup } from '../types/IGroup';

// TODO: V2
export const useGroupWrapper = (group: ApiTypes.v1.GroupResponse) => {
  const g = new GroupWrapper(group);
  const id = g.getId();
  const title = g.getTitle();
  const memberCount = g.getMembersCount();
  const pendingMembersCount = g.getPendingMembersCount();
  const membersName = g.getMembersName();
  const privacyLevel = g.getPrivacy();
  const getScaledLogo = (w: number, h: number) => g.getScaledLogo(w, h);
  const logo = g.getLogo();
  const logoPosition = g.getLogoPosition();
  const { relationship, roles } = group;
  const joined = isJoined(group as any);
  const admin = isAdmin(group as any);
  return {
    id,
    memberCount,
    pendingMembersCount,
    membersName,
    privacyLevel,
    getScaledLogo,
    relationship,
    roles,
    title,
    joined,
    logo,
    logoPosition,
    admin,
  } as IGroup;
};
