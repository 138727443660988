import React from 'react';

export interface InjectedWebOutProps {
  closeWebOutModal(): void;
  openWebOutModal(): void;
  isWebOutModalOpened: boolean;
}

interface WebOutState {
  isWebOutModalOpened: boolean;
}

export const withWebOut = <P extends InjectedWebOutProps>(
  Component: React.ComponentType<P>,
) =>
  class WithWebOut extends React.Component<
    Pick<P, Exclude<keyof P, InjectedWebOutProps>>,
    WebOutState
  > {
    state: WebOutState = {
      isWebOutModalOpened: false,
    };
    openWebOutModal = () => this.setState({ isWebOutModalOpened: true });
    closeWebOutModal = () => this.setState({ isWebOutModalOpened: false });
    render() {
      return (
        <Component
          {...(this.props as P)}
          isWebOutModalOpened={this.state.isWebOutModalOpened}
          openWebOutModal={this.openWebOutModal}
          closeWebOutModal={this.closeWebOutModal}
        />
      );
    }
  };
