import React, { useMemo } from 'react';
import { Button as TPAButton, ButtonProps } from 'wix-ui-tpa/Button';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';

import { st, classes, vars } from './Button.st.css';
import { settingsParams } from '../../settings/groups-list/settings';
import { stylesParams } from '../../settings/groups-list/styles';
import { ButtonType } from '../../settings/groups-list/settingsConstants';
import { Theme } from '../../context/theme';

export const Button: React.FC<ButtonProps & Theme> = ({ style, ...props }) => {
  const settings = useSettings();
  const styles = useStyles();

  const buttonType = settings.get(settingsParams.buttonType);
  const buttonStyle = useMemo(() => {
    const buttonBorderRadius = styles.get(stylesParams.buttonBorderRadius);

    // If user has set border radius then do nothing and just use it from stylable
    if (buttonBorderRadius !== undefined) {
      return null;
    }
    const isRounded = buttonType === ButtonType.rounded;

    if (isRounded) {
      return {
        [vars.radius]: '100px',
      };
    }

    return null;
  }, [buttonType]);

  const mergedStyle = useMemo(
    () => ({
      ...buttonStyle,
      ...style,
    }),
    [style, buttonStyle],
  );

  return (
    <TPAButton
      {...props}
      className={st(
        classes.root,
        { bw: !!props.forceBlackAndWhite, buttonType },
        props.className,
      )}
      style={mergedStyle}
    />
  );
};

Button.displayName = 'Button';
