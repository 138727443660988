import {
  MembershipQuestionAnswer,
  MembershipQuestion,
} from '@wix/ambassador-social-groups-v2-membership-question/types';
import { ApiTypes } from '@wix/social-groups-api';

export function answerToV2({
  questionId,
  textAnswer,
}: ApiTypes.v1.QuestionIdAnswer): MembershipQuestionAnswer {
  return { id: questionId!, text: textAnswer! };
}

export function questionToV1({
  id,
  text,
  required,
}: MembershipQuestion): ApiTypes.v1.Question {
  return { required, questionId: id, textQuestion: text };
}
