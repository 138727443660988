import React from 'react';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { IconButton } from 'wix-ui-tpa/IconButton';
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall';

import { st, classes } from './TPAModal.st.css';
import { MODAL_CLOSE } from 'common/components/Modal/dataHooks';

export interface TPAModalProps {
  /**
   * Describing if the modal should be shown or not.
   */
  isOpen: boolean;

  /**
   * Function that will be run when the modal is requested to be closed (by clicking on overlay)
   * Note: It is not called if isOpen is changed by other means.
   */
  onRequestClose(): void;

  /**
   * Describing if the modal should be shown in full screen or not.
   */
  inFullScreen?: boolean;

  /**
   * Describing if the modal should be shown with close button or not.
   */
  withCloseButton?: boolean;

  /**
   * Describing if the modal should be shown with dark background overlay or not.
   */
  withBackground?: boolean;
  aria?: {
    labeledBy?: string;
  };
  className?: string;
  style?: React.CSSProperties;
  dataHook?: string;
}

interface DefaultProps {
  inFullScreen: boolean;
  withCloseButton: boolean;
  withBackground: boolean;
  aria: {
    labeledBy: string;
  };
}

interface State {}

/** The modal component provides a solid foundation for creating dialogs, popovers, lightboxes, or whatever else. */
export class TPAModal extends React.Component<TPAModalProps, State> {
  static displayName = 'TPAModal';
  static defaultProps: DefaultProps = {
    inFullScreen: false,
    withCloseButton: true,
    withBackground: true,
    aria: {
      labeledBy: 'dialog-label',
    },
  };

  private $container!: HTMLDivElement;
  private readonly $closeBnt: React.RefObject<HTMLButtonElement> =
    React.createRef();
  private bodyOverflow!: string;
  handleContentClick = (event: any) => event.stopPropagation();

  componentDidMount() {
    const { isOpen } = this.props;

    if (isOpen) {
      this.setBodyOverflow();
      this.$container.focus();
    }

    window.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    this.resetBodyOverflow();
  }

  private setBodyOverflow() {
    this.bodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
  }

  private resetBodyOverflow() {
    document.body.style.overflow = this.bodyOverflow || '';
  }

  handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.props.isOpen) {
      this.props.onRequestClose();
    }
  };

  componentDidUpdate(props: Readonly<TPAModalProps>) {
    const openChanged = props.isOpen !== this.props.isOpen;
    // onOpen
    if (openChanged && this.props.isOpen) {
      this.$container.focus();
      this.setBodyOverflow();
    }
    // onClose
    if (openChanged && !this.props.isOpen) {
      this.resetBodyOverflow();
    }
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      withBackground,
      withCloseButton,
      inFullScreen,
      children,
      aria,
      style,
      dataHook,
      ...rest
    } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <TPAComponentsConsumer>
        {({ mobile }) => (
          <div
            className={st(
              classes.root,
              { mobile, withBackground, inFullScreen } as any,
              rest.className,
            )}
            data-mobile={mobile}
            onClick={onRequestClose}
            style={style}
            data-hook={dataHook}
          >
            <div
              className={classes.contentWrapper}
              onClick={this.handleContentClick}
              role="dialog"
              aria-modal="true"
              tabIndex={-1}
              ref={($el) => (this.$container = $el!)}
            >
              {withCloseButton ? (
                <div className={classes.closeButtonWrapper}>
                  <span className={classes.closeButton}>
                    <IconButton
                      className={classes.closeIcon}
                      aria-label="Close the dialog window"
                      icon={<CloseSmall />}
                      onClick={onRequestClose}
                      innerRef={this.$closeBnt}
                      data-hook={MODAL_CLOSE}
                    />
                  </span>
                </div>
              ) : null}
              <div className={classes.content}>{children}</div>
              <div aria-hidden="true" tabIndex={0} onFocus={this.lastFocus} />
            </div>
          </div>
        )}
      </TPAComponentsConsumer>
    );
  }

  private readonly lastFocus = (e: React.FocusEvent<HTMLElement>) => {
    try {
      e.target.blur();
      if (this.$closeBnt && this.$closeBnt.current) {
        this.$closeBnt.current.focus();
      } else {
        this.$container.focus();
      }
    } catch (error) {
      console.log('[TPAModal.lastFocus] Error');
    }
  };
}
