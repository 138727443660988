import React from 'react';
import { ResizeOptions } from 'wix-ui-tpa/dist/src/components/Image/types';

import { GroupCardMedia, GroupCardMediaProps } from '../media/GroupCardMedia';
import { classes, st } from './Cover.st.css';
import { ICoverSettings } from './IGroupCardSettings';
import {
  ImageCrop,
  LayoutType,
} from '../../../settings/groups-list/settingsConstants';

interface Props
  extends Omit<GroupCardMediaProps, 'ratio'>,
    Omit<ICoverSettings, 'showImage'> {
  groupListLayout: LayoutType;
  mobile?: boolean;
}

export const Cover: React.FC<Props> = ({
  groupListLayout,
  groupUrl,
  imageRatio,
  goToGroup,
  imageCrop,
  url,
  mobile,
}) => {
  const resize =
    imageCrop === ImageCrop.FIT ? ResizeOptions.contain : ResizeOptions.cover;

  return (
    <div
      className={st(classes.root, {
        ratio: imageRatio,
        layout: groupListLayout,
        mobile: mobile!,
      })}
    >
      <GroupCardMedia
        url={url}
        ratio={imageRatio}
        groupUrl={groupUrl}
        goToGroup={goToGroup}
        resize={resize}
      />
    </div>
  );
};

Cover.displayName = 'Cover';
