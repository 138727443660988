import React from 'react';
import { CTA } from './CTA';
import { Card, Order } from './Card';
import { IGroupCardSettings } from './IGroupCardSettings';
import { Cover } from './Cover';
import { Details } from './Details';
import { useGroupWrapper } from '../../../hooks/useGroupWrapper';
import { useGroupLabels } from './useGroupLabels';
import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from '../../../bi-logger/types';
import { useBi } from '@wix/yoshi-flow-editor';
import { ApiTypes } from '@wix/social-groups-api';
import { groupAdapter } from '@wix/social-groups-api/dist/src/model/Group/v2/GroupAdapter';
import {
  ImageLayout,
  LayoutType,
} from '../../../settings/groups-list/settingsConstants';
import { groupCoverUrl } from '../../../utils/groupCoverUrl';
import { useUser } from '../../../context/user/useUser';

interface Props {
  groupUrl: string;
  goToGroup(event: any): void;
  mobile: boolean;
  group: ApiTypes.v1.GroupResponse;
  settings: IGroupCardSettings;
  even?: boolean;
  fromOrigin?: string;
}

export const GroupCard: React.FC<Props> = (props) => {
  const { mobile, groupUrl, goToGroup, group, settings, even, fromOrigin } =
    props;
  const {
    showButton,
    groupListLayout,
    imageRatio,
    imageCrop,
    imageLayout,
    showImage,
    cardHeight,
    ...detailsSettings
  } = settings;
  const bi = useBi();
  const g = useGroupWrapper(group as any);
  const coverUrl = groupCoverUrl(g, { targetHeight: cardHeight });
  const { ctaPriority, ctaLabel, ...labels } = useGroupLabels(g);
  const { userActions } = useUser();
  let order: Order;
  if (groupListLayout === LayoutType.sideBySide) {
    order = imageLayout === ImageLayout.LTR ? Order.LTR : Order.RTL;
    if (imageLayout === ImageLayout.MIXED && even) {
      order = Order.LTR;
    }
  }
  const onClickHandler = (event: any) => {
    event.stopPropagation();
    if (g.joined) {
      bi.report(
        groupsSelectGroupFromList({
          origin: 'view_btn',
          userEntry: BIUserEntry.SITE,
          groupId: g.id,
        }),
      );
      if (groupUrl) {
        window.location.href = groupUrl;
      }
    }
    userActions.changeGroupMembership(
      groupAdapter(group) as any,
      undefined,
      fromOrigin,
    ); // TODO
  };

  return (
    <Card
      layout={groupListLayout}
      order={order!}
      cover={
        showImage ? (
          <Cover
            groupUrl={groupUrl}
            goToGroup={goToGroup}
            groupListLayout={groupListLayout}
            imageLayout={imageLayout}
            imageCrop={imageCrop}
            imageRatio={imageRatio}
            url={coverUrl!}
            mobile={mobile}
          />
        ) : null
      }
      details={
        <Details
          groupUrl={groupUrl}
          mobile={mobile}
          group={g}
          settings={detailsSettings}
          labels={labels}
          groupListLayout={groupListLayout}
        />
      }
      cta={
        showButton ? (
          <CTA
            label={ctaLabel}
            priority={ctaPriority}
            onClick={onClickHandler}
          />
        ) : null
      }
      textAlignment={detailsSettings.textAlignment}
      mobile={mobile}
    />
  );
};

GroupCard.displayName = 'GroupCard';
