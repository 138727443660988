import {
  I$WWrapper,
  INavigateToPageOptions,
  INavigateToSectionOptions,
  IWebStorage,
  IWidgetController,
  IWixAPI,
  SiteStructure,
  StructurePage,
} from '@wix/native-components-infra/dist/src/types/types';
import { GROUPS_APP_ID } from '../../config/constants';
import { PubSubEventTypes } from '../../components/Group/controllers/pubSub/PubSubEventTypes';
import { BaseControllerContext } from './BaseControllerContext';
import { BasicStorage } from '@wix/social-groups-api/dist/src/model/storage/BasicStorage';

interface AppStructurePage extends StructurePage {
  applicationId?: string;
}

export abstract class BaseWidgetController<IState>
  extends BaseControllerContext
  implements IWidgetController
{
  protected state: Partial<IState> = {};
  private memoryStorage!: IWebStorage;

  setState(props: Partial<IState>) {
    this.state = { ...this.state, ...props };
    this.controllerConfig.setProps(props);
  }

  public getState() {
    return this.state;
  }

  public abstract pageReady($w?: I$WWrapper, wixAPI?: IWixAPI): Promise<any>;

  public updateConfig($w: I$WWrapper, updatedConfig: { [key: string]: any }) {}

  public onBeforeUnLoad() {}

  getInitialProps(): Promise<Partial<IState>> {
    // Props for SSR
    return Promise.resolve({});
  }

  getMemoryStorage(): IWebStorage {
    if (!this.memoryStorage) {
      this.memoryStorage =
        this.controllerConfig.platformAPIs.storage.memory || new BasicStorage();
    }
    return this.memoryStorage;
  }

  private widgetId() {
    return this.controllerConfig.type;
  }

  getDashboardLink(): string {
    const metaSiteId = this.getMetaSiteId();

    if (metaSiteId === '' || !this.isUserAdmin()) {
      return '';
    }

    return `//manage.wix.com/dashboard/${metaSiteId}/social-groups-dashboard/`;
  }

  getSectionUrl(
    sectionId: string,
  ): Promise<{ url?: string; relativeUrl?: string; error?: any }> {
    return this.controllerConfig.wixCodeApi.site.getSectionUrl({
      sectionId,
      appDefinitionId: this.appDefId,
    });
  }

  navigateToSection(sectionInfo?: INavigateToSectionOptions) {
    return this.controllerConfig.wixCodeApi.location.navigateToSection(
      sectionInfo,
    );
  }

  navigateTo(pageInfo?: INavigateToPageOptions) {
    return this.controllerConfig.wixCodeApi.location.navigateTo?.(pageInfo);
  }

  getSiteStructure(options?: {
    includePageId: boolean;
  }): Promise<SiteStructure> {
    return Promise.resolve(
      this.controllerConfig.wixCodeApi.site.getSiteStructure(options),
    );
  }

  getPages(): Promise<StructurePage[]> {
    return this.getSiteStructure({
      includePageId: true,
    }).then((structure) => structure.pages);
  }

  getAppPages(appDefId: string, withHomepage?: boolean) {
    return this.getPages().then((pages) => {
      return pages.filter((page: AppStructurePage) => {
        return (
          (page.applicationId && page.applicationId === appDefId) ||
          (withHomepage && page.isHomePage)
        );
      });
    });
  }

  getGroupPages(withHomepage?: boolean): Promise<AppStructurePage[]> {
    return this.getAppPages(GROUPS_APP_ID, withHomepage);
  }

  isInSEO() {
    return this.controllerContext.controllerConfig.wixCodeApi.seo.isInSEO();
  }

  setProps(props: Partial<IState>) {
    this.controllerConfig.setProps(props);
  }

  publish<T>(type: PubSubEventTypes, payload: T, persistent = false) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        type,
        payload,
        persistent,
      );
    } catch (e) {
      console.log('[BaseWidgetController.publish] Error');
    }
  }

  subscribe<T>(
    type: PubSubEventTypes,
    fn: (payload: { data: T }) => void,
    isPersistent: boolean = false,
  ) {
    try {
      this.controllerConfig.platformAPIs.pubSub.subscribe(
        type,
        fn,
        isPersistent,
      );
    } catch (e) {
      console.log('[BaseWidgetController.subscribe] Error');
    }
  }
}
