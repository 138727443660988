import React from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';

import { GroupsProvider } from './GroupsContext';
import { GroupsActionsProvider } from './GroupsActionsContext';

import { classes, st } from '../Widget/App/App.st.css';
import { GroupsWidgetWithStyles } from '../Widget/App/types';
import { isMobileByFormFactor } from '../../../common/utils/utils';
import { GROUPS } from '../../../common/components/GroupList/dataHooks';
import { UserContextProvider } from '../../../common/context/user/UserContext';
import { GroupRequestsContextProvider } from '@wix/social-groups-api/dist/src/context/GroupRequests/GroupRequestsContext';

export const GroupsAppProvider: React.FC<GroupsWidgetWithStyles> = (props) => {
  const {
    actions,
    host: { formFactor },
    children,
    navigatingToGroup,
    isRTL,
  } = props;
  const isMobile = isMobileByFormFactor(formFactor);

  const dir = isRTL ? 'rtl' : null;
  return (
    <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
      <UserContextProvider value={props}>
        <GroupsProvider value={props}>
          <GroupsActionsProvider value={actions || ({} as any)}>
            <GroupRequestsContextProvider value={props}>
              <div
                data-hook={GROUPS}
                className={st(classes.root, {
                  navigating: !!navigatingToGroup,
                })}
                dir={dir as any}
              >
                {children}
              </div>
            </GroupRequestsContextProvider>
          </GroupsActionsProvider>
        </GroupsProvider>
      </UserContextProvider>
    </TPAComponentsProvider>
  );
};

GroupsAppProvider.displayName = 'GroupsAppProvider';
