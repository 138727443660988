import React from 'react';
import { ThemeConsumer } from './ThemeContext';
import { Theme } from './Theme';

export const withTheme =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>,
  ): React.ComponentType<Omit<P, keyof Theme>> =>
  (props) => {
    return (
      <ThemeConsumer>
        {(context) => {
          return <WrappedComponent {...(props as P)} {...context} />;
        }}
      </ThemeConsumer>
    );
  };
