import { ApiTypes } from '@wix/social-groups-api';

const ALLOWED_ROLES = [ApiTypes.v1.RoleInGroup.GROUP_ADMIN];
const onlyAllowedRoles = (role: any) => ALLOWED_ROLES.includes(role);

export function getAllowedRoles(
  roles: ApiTypes.v1.RoleInGroup[] = [],
): ApiTypes.v1.RoleInGroup[] {
  return roles.filter(onlyAllowedRoles);
}
