import React from 'react';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ApiTypes } from '@wix/social-groups-api';

import { st, classes } from './RoleBadges.st.css';
import { getAllowedRoles } from './helpers';

export interface RoleBadgesProps {
  relationship: ApiTypes.v1.RelationshipWithGroup | null;
  roles: ApiTypes.v1.RoleInGroup[] | null;
  className?: string;
  getBadgeClassName?: (isAdmin: boolean) => string;
}

const defaultGetBadgeClassName = (isAdmin: boolean) =>
  st(classes.badge, { isAdmin });

export const RoleBadgesComponent: React.FC<RoleBadgesProps> = ({
  relationship,
  roles,
  getBadgeClassName,
  ...restProps
}) => {
  const { t } = useTranslation();
  const shouldShowRoles =
    relationship === ApiTypes.v1.RelationshipWithGroup.JOINED;

  const allowedRoles = getAllowedRoles(roles!);

  if (!shouldShowRoles || !allowedRoles.length) {
    return null;
  }

  const badgeClassNameFn =
    getBadgeClassName == null ? defaultGetBadgeClassName : getBadgeClassName;

  return (
    <div className={st(classes.root, {}, restProps.className)}>
      {allowedRoles.map((role) => {
        const isAdmin = role === ApiTypes.v1.RoleInGroup.GROUP_ADMIN;

        return (
          <Badge
            key={role}
            data-hook="role-badge"
            priority={BADGE_PRIORITY.light}
            className={badgeClassNameFn(isAdmin)}
          >
            {t(`groups-web.roles.${role.toLowerCase()}`)}
          </Badge>
        );
      })}
    </div>
  );
};

export const RoleBadges =
  RoleBadgesComponent as React.ComponentType<RoleBadgesProps>;
